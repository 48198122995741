export default [
    'adorable',
    'adventurous',
    'aggressive',
    'agreeable',
    'alert',
    'alive',
    'amused',
    'average',
    'beautiful',
    'better',
    'bewildered',
    'bored',
    'brainy',
    'brave',
    'breakable',
    'bright',
    'busy',
    'calm',
    'careful',
    'cautious',
    'charming',
    'cheerful',
    'clean',
    'clear',
    'clever',
    'cloudy',
    'colorful',
    'combative',
    'comfortable',
    'concerned',
    'confused',
    'cooperative',
    'courageous',
    'crowded',
    'curious',
    'cute',
    'dangerous',
    'dark',
    'defiant',
    'delightful',
    'determined',
    'different',
    'difficult',
    'distinct',
    'dizzy',
    'doubtful',
    'drab',
    'dull',
    'eager',
    'easy',
    'elated',
    'elegant',
    'enchanting',
    'encouraging',
    'energetic',
    'enthusiastic',
    'envious',
    'excited',
    'expensive',
    'exuberant',
    'fair',
    'famous',
    'fancy',
    'fantastic',
    'fierce',
    'fine',
    'foolish',
    'fragile',
    'friendly',
    'funny',
    'gentle',
    'gifted',
    'glorious',
    'good',
    'graceful',
    'grieving',
    'grumpy',
    'happy',
    'healthy',
    'helpful',
    'helpless',
    'hilarious',
    'hungry',
    'impossible',
    'inexpensive',
    'innocent',
    'inquisitive',
    'jolly',
    'joyous',
    'kind',
    'light',
    'lively',
    'long',
    'lovely',
    'lucky',
    'magnificent',
    'misty',
    'modern',
    'motionless',
    'muddy',
    'mushy',
    'mysterious',
    'nice',
    'open',
    'outrageous',
    'outstanding',
    'perfect',
    'plain',
    'pleasant',
    'poised',
    'powerful',
    'precious',
    'proud',
    'puzzled',
    'quaint',
    'real',
    'relieved',
    'rich',
    'shiny',
    'sleepy',
    'smiling',
    'smoggy',
    'sparkling',
    'splendid',
    'spotless',
    'stormy',
    'strange',
    'successful',
    'super',
    'talented',
    'tame',
    'tasty',
    'tender',
    'thankful',
    'thoughtful',
    'vast',
    'victorious',
    'wandering',
    'witty',
    'zealous'
]
