const { createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = require('vue')

module.exports = function render (_ctx, _cache) {
    return (_openBlock(), _createBlock('svg', {
        width: 24,
        height: 24,
        fill: 'currentColor',
        viewBox: '0 0 24 24'
    }, [
        (_openBlock(), _createBlock('g', {
            'clip-path': 'url(#clip0_3717_9251)'
        }, [
            _createVNode('path', {
                d: 'M20 0H4C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4C24 1.79086 22.2091 0 20 0Z',
                fill: 'currentFill'
            }),
            _createVNode('path', {
                d: 'M16.2 11.63H11.63V16.2H16.2V11.63Z',
                fill: 'white'
            }),
            _createVNode('path', {
                'fill-rule': 'evenodd',
                'clip-rule': 'evenodd',
                d: 'M8.27997 16.18V18.13C8.27997 18.5 8.42997 18.87 8.69997 19.14C8.96997 19.41 9.32997 19.56 9.70997 19.56H11.66V20.67C11.66 20.82 11.72 20.97 11.83 21.08C12.05 21.3 12.43 21.3 12.65 21.08C12.76 20.97 12.82 20.83 12.82 20.67V19.56H15.03V20.67C15.03 20.82 15.09 20.97 15.2 21.08C15.42 21.3 15.8 21.3 16.02 21.08C16.13 20.97 16.19 20.83 16.19 20.67V19.56H18.14C18.51 19.56 18.88 19.41 19.15 19.14C19.42 18.87 19.57 18.51 19.57 18.13V16.18H20.68C20.83 16.18 20.98 16.12 21.09 16.01C21.2 15.9 21.26 15.75 21.26 15.6C21.26 15.45 21.2 15.3 21.09 15.19C20.98 15.08 20.84 15.02 20.68 15.02H19.57V12.81H20.68C20.83 12.81 20.98 12.75 21.09 12.64C21.2 12.53 21.26 12.38 21.26 12.23C21.26 12.08 21.2 11.93 21.09 11.82C20.98 11.71 20.83 11.65 20.68 11.65H19.57V9.69996C19.57 9.32996 19.42 8.95996 19.15 8.68996C18.88 8.41996 18.52 8.26996 18.14 8.26996H12.82V7.15996C12.82 7.00996 12.76 6.85996 12.65 6.74996C12.43 6.52996 12.05 6.52996 11.83 6.74996C11.72 6.85996 11.66 6.99996 11.66 7.15996V8.26996H9.70997C9.32997 8.26996 8.96997 8.41996 8.69997 8.68996C8.43997 8.94996 8.27997 9.31996 8.27997 9.69996V11.65H7.16997C7.01997 11.65 6.86997 11.71 6.75997 11.82C6.64997 11.93 6.58997 12.08 6.58997 12.23C6.58997 12.38 6.64997 12.53 6.75997 12.64C6.86997 12.75 7.01997 12.81 7.16997 12.81H8.27997V16.18ZM18.4 18.39H9.43997V9.42996H18.4V18.39Z',
                fill: 'white'
            }),
            _createVNode('path', {
                'fill-rule': 'evenodd',
                'clip-rule': 'evenodd',
                d: 'M8.28997 15.02H6.07997V6.05996H15.04V8.26996H16.2V6.31996C16.2 5.94996 16.05 5.57996 15.78 5.30996C15.52 5.04996 15.15 4.88996 14.77 4.88996H12.82V3.77996C12.82 3.62996 12.76 3.47996 12.65 3.36996C12.43 3.14996 12.05 3.14996 11.83 3.36996C11.72 3.47996 11.66 3.61996 11.66 3.77996V4.88996H9.44997V3.77996C9.44997 3.62996 9.38997 3.47996 9.27997 3.36996C9.05997 3.14996 8.67997 3.14996 8.45997 3.36996C8.34997 3.47996 8.28997 3.61996 8.28997 3.77996V4.88996H6.33997C5.95997 4.88996 5.59997 5.03996 5.32997 5.30996C5.06997 5.56996 4.90997 5.93996 4.90997 6.31996V8.26996H3.79997C3.64997 8.26996 3.49997 8.32996 3.38997 8.43996C3.27997 8.54996 3.21997 8.69996 3.21997 8.84996C3.21997 8.99996 3.27997 9.14996 3.38997 9.25996C3.49997 9.36996 3.64997 9.42996 3.79997 9.42996H4.90997V11.64H3.79997C3.64997 11.64 3.49997 11.7 3.38997 11.81C3.27997 11.92 3.21997 12.06 3.21997 12.22C3.21997 12.38 3.27997 12.52 3.38997 12.63C3.49997 12.74 3.64997 12.8 3.79997 12.8H4.90997V14.75C4.90997 15.12 5.05997 15.49 5.32997 15.76C5.59997 16.03 5.95997 16.18 6.33997 16.18H8.27997V15.02H8.28997Z',
                fill: 'white'
            })
        ])),
        _createVNode('defs', null, [
            _createVNode('clipPath', {
                id: 'clip0_3717_9251'
            }, [
                _createVNode('rect', {
                    width: '24',
                    height: '24',
                    fill: 'white'
                })
            ])
        ])
    ]))
}
